import React, { useState, useEffect } from "react";
import AddNewRole from "./AddNewRole";
import EditRole from "./EditRole";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Backdrop from "@material-ui/core/Backdrop";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { exportToExcel } from "../../utils/helper";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import exportLogo from "../../assets/Export.png";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import deleteLogo from "../../assets/Delete.png";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import { API } from "../../api/property";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { SnackbarProvider, useSnackbar } from "notistack";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Divider from "@material-ui/core/Divider";
import "../Users/Pagination.css";
import ErrorDialog from "../../utils/ErrorDialog";
import RoleTableView from './RoleComponent/RoleTableView';
import callXhrRequest from "../../utils/xhrRequestHandler";
import { arrayOfErrorsRefresh, arrayOfErrorsLogout } from '../../utils/helper';
import { useStore } from "mobx-store-provider";
import BreadcumbComponent from '../../utils/breadcurmb';
import { useApplicationContext } from "../../contexts/ApplicationContext";
import ApplicationSelector from "../Devices/DeviceInstallation/ApplicationSelector";

const textFieldBorder ="1px solid #464659"
const displayProp = "inline-block"

const CssTextField = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
    },
    "& .MuiInput-multiline": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      marginTop: "32px",
    },
    "& .MuiInput-underline": {
      "&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hidden: {
      display: "none",
    },
    formControl: {
      margin: "0 25px",
    },
    gridRoot: {
      flexGrow: 1,
      padding: "0px 24px",
      backgroundColor: "#2B2B36",
      margin: "0px 20px 0px 20px",
      borderBottom: "solid thin #33333F",
    },
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    flexContainer: {
      display: "flex",
      flexDirection: "row",
    },
    listButtonWrap: {
      marginLeft: "20px",
    },
    listItem: {
      width: "auto",
      padding: "0px",
      marginRight: "20px",
      fontSize: "16px",
      color: "#FFFFFF",
      lineHeight: "19px",
    },
    listItemText: {
      fontSize: "16px",
      lineHeight: "19px",
    },
    gridRootApp: {
      flexGrow: 1,
      padding: "0",
      margin: "0 20px 20px",
    },
    tableWrapper: {
      padding: "0 20px",
      marginTop: "15px",
      boxShadow: "none",
    },
    table: {
      minWidth: "100%",
      maxWidth: "100%",
    },
    listButton: {
      padding: "0px",
      textTransform: "none",
      fontSize: "14px",
      whiteSpace: "nowrap",
    },
    rightSec: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      whiteSpace: "nowrap",
      padding: "5px 0px",
      fontSize: "12px",
      lineHeight: "14px",
      "& p": {
        fontSize: "12px",
        padding: "11px 0px",
        marginRight: "30px",
      },
      "& button": {
        background: "#0089FF",
        borderRadius: "2px",
        fontSize: "16px",
        color: "#FCFCFC",
        padding: "6px 20px",
        marginRight: "10px",
        "&:hover": {
          background: "#0089FF",
        },
      },
    },
    marginLeft15: {
      backgroundColor: "#2B2B36",
      borderBottom: `${textFieldBorder}`,
      color: "#FFFFFF",
    },
    bgColorBlue: {
      color: "#1976d2 !important",
    },
    typoRoot: {
      paddingLeft: "24px",
    },
    menuButtonRoot: {
      padding: "0px",
    },
    viewDetails: {
      "&:hover": {
        color: "#0089FF !important",
      },
    },
    menuRoot: {
      backgroundColor: "#1D1D25",
      border: "1px solid #33333F",
      boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
      borderRadius: "2px",
    },
    menuitem: {
      color: "white",
      fontSize: "14px",
      paddingRight: "40px",
      "& svg": {
        marginRight: "8px",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    colorWhite: {
      color: "#FFFFFF",
    },
    dcFieldValue: {
      margin: "10px !important",
    },
    accordionRoot: {
      color: "#FFFFFF",
      width: "100%",
      margin: "0px",
      padding: "0px",
      boxShadow: "none",
      "& .MuiAccordionSummary-content": {
        margin: "0px",
      },
      minHeight: "auto !important",
    },
    dialogRoot: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      minWidth: "800px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "1px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: "1px solid rgba(235,235,245,0.35)",
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    isExpand: {
      position: "relative",
      top: "1.25rem",
    },
    icon: {
      color: "#FFFFFF",
    },
    options: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
      cursor: "pointer",
    },
    fieldRoot: {
      marginTop: "16px",
    },
    spaceRight: {
      paddingRight: "10px",
    },
    fieldWrapper: {
      marginTop: "20px",
    },
    field: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
    },
    textfield: {
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      boxSizing: "border-box",
    },
    paddingTop: {
      padding: "1.5rem 0 !important",
    },
    bgColor: {
      background: "#2B2B36",
      margin: "1px 0",
      padding: "7px 0",
    },
    dialogRootSmall: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      position: "absolute",
      top: "10%",
      minWidth: "416px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: "1px solid rgba(235,235,245,0.35)",
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    dividerColor: {
      background: "#464659",
      margin: "1em",
    },
    noSpaceNoMin: {
      margin: "0px !important",
      padding: "0px !important",
      minHeight: "auto !important",
    },
    tblpaginate: {
      color: "#fff",
    },
    tblfooter: {
      position: "absolute",
      display: `${displayProp}`
    },
    tblfooter1: {
      position: "sticky",
      padding: "10px 0",
      display: "flex",
      alignItems: "center",
    },
    tblfooter2: {
      position: "sticky",
      right: "0",
      padding: "20px 25px",
      display: "flex",
      marginLeft: "77.4rem",
      marginTop: "-58px"
    },
    tblpaginatenumber: {
      color: "#fff",
      marginRight: "13px"
    },
    dropdown: {
      width: "207px",
      height: "40px",
      background: "transparent",
      padding: "10px",
      color: "#FFFFFF",
      border: "1px solid #4CA5FF",
      font: "400 13.3333px 'Avenir Next'",
      borderRadius: "2px",
      marginRight: "30px",
      "& option": {
        background: "#1D1D25",
      },
    },
    label: {
      fontSize: "18px",
      lineHeight: "21px",
      marginRight: "18px",
      color: "#FFF",
    },
    alignCentre: {
      alignItems: "center",
      marginTop: "0.5rem",
      marginLeft: "0.5em"
    },
    paginateUser: {
      color: "#fff",
    },
    paginateView: {
      color: "#fff",
    },
    labels: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
    },
  })
);

const DialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: "20px",
      padding: 0,
      color: "#FFFFFF",
    },
    title: {
      fontSize: "21px",
      lineHeight: "25px",
      fontWeight: "bold",
    },
    closeButton: {
      position: "absolute",
      right: "2px",
      top: "15px",
      color: "#FFFFFF",
      fontSize: "14px",
    },
  });
const DialogContent = withStyles((theme: Theme) => ({
  root: {
    margin: "0px 15px",
    padding: 0,
    color: "#FFFFFF",
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: "20px 15px",
    justifyContent: "space-between",
    padding: 0,
    "& button": {
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
  },
}))(MuiDialogActions);

export interface DialogTitleProps extends WithStyles<typeof DialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}
const DialogTitle = withStyles(DialogTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


function CustomizedTables() {
  const classes = useStyles();
  const { user } = useStore();

  const API_KEY = window.SERVER_DATA.REACT_APP_PM_API_KEY;
  const { enqueueSnackbar } = useSnackbar();
  interface sObj {
    pm_s_id: string;
  }
  interface tObj {
    pm_s_token: string;
  }
  let pmSId: sObj = {
    pm_s_id: "",
  };
  let pmST: tObj = {
    pm_s_token: "",
  };
  pmSId =
    sessionStorage.getItem("pm_s_id") || JSON.parse(JSON.stringify(pmSId));
  pmST =
    sessionStorage.getItem("pm_s_token") || JSON.parse(JSON.stringify(pmST));
  const tokenKey = "sso_token";
  const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isForm, setIsForm] = useState(false);
  const [reload, setReload] = useState(false);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [menu, setMenu] = useState([] as any);
  const [loadRoles, setLoadRoles] = useState(false);
  const [backDrop, setbackDrop] = useState(false);
  const [rolesApplication, setApplication] = useState([] as any);
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [delrole, setDelRole] = useState("");
  const [delapp, setDelApp] = useState("");
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [editItem, setEditItem] = useState({} as any);
  const [viewrole, setViewRoles] = useState([] as any);
  const [openDialog, setOpenDialog] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [search, setSearch] = useState("");
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [exportRadioBtn, setExportRadioBtn] = useState({
    role_name: true,
    description: true,
    no_of_users: true
  });
  // const [app, setApp] = useState("" as any);
  const [allRolesData, setAllRolesData] = useState([] as any);
  const [filteredRoles, setFilteredRoles] = useState([] as any);

  const  { selectedApplication } = useApplicationContext();


  const [rolesInput, setRolesInput] = useState([] as any);
  const handleClick = (item: any) => (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    menu[item] = true;
    setMenu(menu);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (item: any) => () => {
    const menuClose = menu.map((m: any) => false);
    setMenu(menuClose);
    setAnchorEl(null);
  };
  const cancelUserAction = () => {
    setIsForm(false);
    setIsFormEdit(false);
    setLoadRoles(!loadRoles);
  };
  const backDropClose = () => {
    setbackDrop(false);
  };
  const handleEdit = (item: any) => {
    setOpenDialog(false);
    setEditItem(item);
    const menuClose = menu.map((m: any) => false);
    setMenu(menuClose);
    setAnchorEl(null);
    setIsFormEdit(true);
  };
  const updateError = () => {
    setError(false);
    setErrMsg("");
  };
  const dialogHandleDelete = (roleId: any, appId: any) => {
    setOpenDialogDelete(true);
    const menuClose = menu.map((m: any) => false);
    setMenu(menuClose);
    setDelRole(roleId);
    setDelApp(appId);
  };
  const [exportpopup, setExportPopupOpen] = useState(false);
  const dialogHandleCloseDelete = () => {
    setOpenDialogDelete(false);
    setExportPopupOpen(false);
    setExportRadioBtn({
      role_name: true,
      description: true,
      no_of_users: true
    })
  };
  const onView = (roles: any) => {
    const permObj = [] as any;
    roles &&
      roles.permission_mapping.forEach((event: any) => {
        permObj.push({
          component_code: event.component_code,
          read:
            event.permission.split("|").indexOf("read") !== -1 ? true : false,
          modify:
            event.permission.split("|").indexOf("write") !== -1 ? true : false,
          delete:
            event.permission.split("|").indexOf("delete") !== -1 ? true : false,
        });
      });
    setViewRoles(roles);
    setOpenDialog(true);
    const menuClose = menu.map((m: any) => false);
    setMenu(menuClose);
  };
  const dialogHandleClose = () => {
    const menuClose = menu.map((m: any) => false);
    setMenu(menuClose);
    setAnchorEl(null);
    setOpenDialog(false);
  };
  const handleCloseSearch = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setSearchActive(false);
  };
  const handleChangeSearch = (event: any) => {
    const target = event.target.value;
    setSearch(target);
  };

  const exportChange = (event: any) => {
    setExportRadioBtn({
      ...exportRadioBtn,
      [event.target.name]: event.target.checked,
    });
  };
  const handleExport = async () => {
    if (exportRadioBtn.role_name === false && exportRadioBtn.description === false) {
      enqueueSnackbar("Please select at least one column to export.", { variant: "error" });
    } else {
      setTimeout(() => {
        setExportPopupOpen(false);
      }, 100);
      const expRolesChecked = [] as any;
      for (const [key, value] of Object.entries(exportRadioBtn)) {
        if (value) {
          expRolesChecked.push(key);
        }
      }
      setbackDrop(true);
      const exclData = [] as any;
      filteredRoles.forEach((item: any) => {
        const row: any = {};

        if (exportRadioBtn.role_name) {
          row.RoleName = item.role_name;
        }
        if (exportRadioBtn.description) {
          row.Description = item.role_desc;
        }
        if (exportRadioBtn.no_of_users) {
          row.NumberOfUsers = item.no_of_users;
        }
        if (Object.keys(row).length) {
          exclData.push(row);
        }
      });
      const {application_name} = selectedApplication;
      await exportToExcel(exclData, "Roles", exclData.length, application_name);
      setbackDrop(false);
      enqueueSnackbar("Export initiated.", { variant: "success" });
      setTimeout(() => {
        setLoadRoles(!loadRoles);
        setExportRadioBtn({
          role_name: true,
          description: true,
          no_of_users: true
        })
      }, 500);
    }
  };

  useEffect(() => {
    const temp = [...allRolesData];
    setFilteredRoles(temp.filter(function filterRole(role: any) {
      return role.application_id === selectedApplication.application_id
    }));
  }, [selectedApplication])

  // const onChangeApplication = (event: any) => {
  //   setApp(event.target.value);
  //   const temp = [...allRolesData];
  //   setSelectedRoles(temp.filter(function filterRole(role: any) {
  //     return role.application_name === event.target.value
  //   }));
  // }


  useEffect(() => {
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    interface sObj {
      pm_s_id: string;
    }
    interface tObj {
      pm_s_token: string;
    }
    let pmSId: sObj = {
      pm_s_id: "",
    };
    let pmST: tObj = {
      pm_s_token: "",
    };
    pmSId =
      sessionStorage.getItem("pm_s_id") || JSON.parse(JSON.stringify(pmSId));
    pmST =
      sessionStorage.getItem("pm_s_token") || JSON.parse(JSON.stringify(pmST));
    setbackDrop(true);
    // const GETALLAPPLS_API = API["GETALLAPPLS"];
    const GETROLES_INPUT = API["ROLES_APPLS_INPUT"];
    const GETALL_ROLES = API["GETALLROLES"];
    Promise.all([
      // fetch(`${GETALLAPPLS_API}?pm_s_token=${pmST}&pm_s_id=${pmSId}`, {
      //   headers: {
      //     'Authorization': `Bearer ${accessToken.access_token}`,
      //     'Ocp-Apim-Subscription-Key': `${API_KEY}`,
      //     'Ocp-Apim-Trace': `true`
      //   },
      // }),
      fetch(`${GETROLES_INPUT}?pm_s_token=${pmST}&pm_s_id=${pmSId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken.access_token}`,
          'Ocp-Apim-Subscription-Key': `${API_KEY}`,
          'Ocp-Apim-Trace': `true`
        },
      }),
      fetch(`${GETALL_ROLES}?pm_s_token=${pmST}&pm_s_id=${pmSId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken.access_token}`,
          'Ocp-Apim-Subscription-Key': `${API_KEY}`,
          'Ocp-Apim-Trace': `true`
        },
      }),
    ])
      .then(function (responses) {
        return Promise.all(
          responses.map(function (response) {
            return response.json();
          })
        );
      })
      .then(function (response: any) {
        setbackDrop(false);
        if ((response && response[0].status === 401) && (response && arrayOfErrorsRefresh.includes(response[0].message.toLowerCase()))) {
          try {
            const tokenKey = "sso_token";
            setbackDrop(true);
            callXhrRequest().then(function(data){
              if(data.indexOf('error') === -1){
                sessionStorage.setItem(tokenKey, data);
                setbackDrop(true);
                setReload(!reload)
              }
            }).catch(function(error){
              user.triggerLogout();
              console.log('error', error)
            })
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if ((response && response[0].status === 401) && (response && arrayOfErrorsLogout.includes(response[0].message.toLowerCase()))) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }
        if (
          response &&
          response[0].status === 200 &&
          typeof response[0].applications !== "undefined"
        ) {
          setApplication(response && response[0].applications);

        } 
        if (response &&
          response[0].status === 200 &&
          typeof response[0].roles_input !== "undefined") {
          setRolesInput(response && response[0].roles_input[0].portal_components);
        } 
        if (response &&
          response[1].status === 200 &&
          typeof response[1].roles !== "undefined") {
          setAllRolesData(response && response[1].roles);
          setFilteredRoles(response[1].roles.filter(function filterRole(role: any) {
            return (
              role.application_id === selectedApplication.application_id
            );
          }))
        } 

      })
      .catch(function (error) {
        setbackDrop(false);
      });
  }, [loadRoles, accessToken.access_token,reload, API_KEY]);
  const onDelete = () => {
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    const ROLES_API = `${API["GETALLAPPLS"]}/${delapp}/roles/${delrole}?pm_s_token=${pmST}&pm_s_id=${pmSId}`;
    const requestOptions = {
      method: "DELETE",
      headers: {
        'Authorization': `Bearer ${accessToken.access_token}`,
        'Ocp-Apim-Subscription-Key': `${API_KEY}`,
        'Ocp-Apim-Trace': `true`
      },
    };
     fetch(ROLES_API, requestOptions)
      .then(function (response:any) {
        if ((response && response.status === 401) && (response && arrayOfErrorsRefresh.includes(response.message.toLowerCase()))) {
          try {
            const tokenKey = "sso_token";
            setbackDrop(true);
            callXhrRequest().then(function(data){
                sessionStorage.setItem(tokenKey, data);
                setbackDrop(false);
                onDelete();
            }).catch(function(error){
              user.triggerLogout();
              console.log('error', error)
            })
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if ((response && response.status === 401) && (response && arrayOfErrorsLogout.includes(response.message.toLowerCase()))) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }
        if (response && response.status === 200) {
          setOpenDialogDelete(false);
          enqueueSnackbar("Role Deleted", { variant: "success" });
          const menuClose = menu.map((m: any) => false);
          setMenu(menuClose);
          setLoadRoles(!loadRoles);
        } 
      })
      .catch(function (error) {
        console.log(error)
      });
    setOpenDialog(false);
  };

  return (
    <React.Fragment>
      <div
        className={isForm || isFormEdit ? classes.hidden : classes.gridRootApp}
      >
        <Grid container spacing={0} className={classes.alignCentre}>
          <ApplicationSelector />
          {/* <label className={classes.label}>Application: </label>
          <select
            className={classes.dropdown}
            onChange={onChangeApplication}
            value={app}
          >
            {rolesApplication
              .reduce((e: any, c: any) => {
                if (!e.includes(c.application_name)) {
                  e.push(c.application_name);
                }
                return e;
              }, [])
              .map((option: any) => (
                <option value={option}>{option}</option>
              ))}
          </select> */}
        </Grid>
      </div>
      <div className={classes.gridRoot} style={{display: 'flex',alignItems: 'center', paddingTop: '10px', paddingBottom: '10px'}}>
        {isForm?<BreadcumbComponent route='addRole' func={cancelUserAction}/>:isFormEdit?<BreadcumbComponent route='editRole' func={cancelUserAction}/>:<BreadcumbComponent route='roles'/>}
      </div>
      <div className={isForm || isFormEdit ? classes.hidden : classes.gridRoot}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <List classes={{ root: classes.flexContainer }} dense={true}>
              <ListItem classes={{ root: classes.listItem }}>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary="Total Roles"
                  primaryTypographyProps={{
                    style: { display: `${displayProp}` },
                  }}
                  secondary={
                    <span>
                      {filteredRoles && filteredRoles.length}
                    </span>
                  }
                  secondaryTypographyProps={{
                    style: {
                      display: `${displayProp}`,
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      marginLeft: "5px",
                      fontSize: "16px",
                    },
                  }}
                />
              </ListItem>
              <ListItem
                classes={{ root: classes.listItem }}
                className={classes.listButtonWrap}
              >
                <Button
                  variant="text"
                  color="inherit"
                  className={classes.listButton}
                  startIcon={<img alt="Export Logo" src={exportLogo}></img>}
                  onClick={() => setExportPopupOpen(true)}
                >
                  Export
                </Button>
              </ListItem>
              <ListItem
                classes={{ root: classes.listItem }}
                className={classes.listButtonWrap}
              >
                <ClickAwayListener onClickAway={handleCloseSearch}>
                  {searchActive || search !== "" ? (
                    <CssTextField
                      InputProps={{
                        disableUnderline: true,
                      }}
                      InputLabelProps={{
                        shrink: false,
                        className: classes.labels,
                      }}
                      autoComplete="off"
                      value={search}
                      onChange={(event: any) => handleChangeSearch(event)}
                      id="filled-search"
                      type="search"
                      variant="filled"
                    />
                  ) : (
                    <Button
                      variant="text"
                      color="inherit"
                      className={classes.listButton}
                      startIcon={<SearchIcon style={{ color: "#7C7C94" }} />}
                      onClick={() => setSearchActive(true)}
                    >
                      Search
                    </Button>
                  )}
                </ClickAwayListener>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.rightSec}>
              <Button
                variant="contained"
                color="inherit"
                className={classes.listButton}
                endIcon={<AddIcon />}
                size="large"
                onClick={() => {
                  setIsForm(true);
                }}
              >
                Add New Role
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      {isForm && <AddNewRole onCancelUser={cancelUserAction} />}
      {isFormEdit && <EditRole editItem={editItem} onCancelUser={cancelUserAction} onCheckbox={rolesInput} />}
      <Backdrop
        className={classes.backdrop}
        open={backDrop}
        onClick={backDropClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={isForm || isFormEdit ? classes.hidden : ""}>
        

          <RoleTableView
            isForm={isForm}
            isFormEdit={isFormEdit}
            classes={classes}
            selectedApplication={filteredRoles}
            search={search}
            handleClick={handleClick}
            anchorEl={anchorEl}
            menu={menu}
            handleClose={handleClose}
            handleEdit={handleEdit}
            dialogHandleDelete={dialogHandleDelete}
            onView={onView}

          />
        <Dialog
          classes={{ paper: classes.dialogRootSmall }}
          onClose={dialogHandleCloseDelete}
          aria-labelledby="customized-dialog-title"
          open={openDialogDelete}
        >
          <Grid container direction="row" alignItems="center">
            <img
              style={{ marginLeft: "1em" }}
              alt="Delete Logo"
              src={deleteLogo}
            ></img>
            <DialogTitle
              id="customized-dialog-title"
              onClose={dialogHandleCloseDelete}
            >
              Delete Role?
            </DialogTitle>
          </Grid>
          <DialogContent>
            <Typography
              style={{
                fontFamily: "Avenir Next",
                fontSize: "14px",
                lineHeight: "16px",
                display: "flex",
                alignItems: "center",
                margin: "1em",
                color: "#FFFFFF",
              }}
            >
              Are you sure you want to delete this Role? This Role cannot be
              recovered.
            </Typography>
          </DialogContent>
          <Divider variant="middle" classes={{ middle: classes.dividerColor }} />

          <DialogActions>
            <Button
              onClick={dialogHandleCloseDelete}
              variant="outlined"
              className="dcBtDelete"
            >
              Cancel
            </Button>
            <Button
              onClick={onDelete}
              variant="contained"
              className="dcBtEdit"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          classes={{ paper: classes.dialogRoot }}
          onClose={dialogHandleClose}
          aria-labelledby="customized-dialog-title"
          open={openDialog}
        >
          <DialogTitle id="customized-dialog-title" onClose={dialogHandleClose}>
            View Role Details
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={0} className="dcOuter">
              <Grid item xs={6}>
                <Typography className="dcTitle">Role Name</Typography>
                <Typography className="dcFieldValue">
                  {viewrole && viewrole.role_name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className="dcTitle">Description</Typography>
                <Typography className="dcFieldValue">
                  {viewrole && viewrole.role_desc}
                </Typography>
              </Grid>
            </Grid>

            <Grid container className={classes.paddingTop}>
              <Grid
                container
                spacing={0}
                className="dcOuterPadding"
                style={{ paddingBottom: "1rem" }}
              >
                <Grid item xs={6}>
                  <Typography className={classes.colorWhite}>
                    Permissions
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={classes.colorWhite}>Read</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={classes.colorWhite}>Write</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={classes.colorWhite}>Create/Delete</Typography>
                </Grid>

              </Grid>

              {Array.isArray(viewrole.permission_mapping) &&
                viewrole.permission_mapping.map((role: any, index: number) => {
                  const checkedPermissions =
                    (role.permission && role.permission.split("|")) || [];
                  const rolePermission = rolesInput.find((e: any) => e.component_code === role.component_code)
                  const availablePermission = (rolePermission && rolePermission.possible_permissions.split("|")) || [];
                  return (
                    <>
                      <Grid
                        container
                        spacing={0}
                        className={classes.marginLeft15}
                      >
                        <Grid container item xs={12} spacing={3}>
                          <Grid item xs={6}>
                            <Typography className={classes.dcFieldValue}>
                              {role.component_desc}
                            </Typography>
                          </Grid>
                          {
                            ["read", "write", "delete"].map((i: any) => {
                              return (
                                <Grid item xs={2}>
                                  {availablePermission.includes(i) && (
                                    <Checkbox
                                      defaultChecked={checkedPermissions.includes(i)}
                                      disabled
                                      color="primary"
                                      inputProps={{
                                        "aria-label": "secondary checkbox",
                                      }}
                                      className={classes.bgColorBlue}
                                    />
                                  )}

                                </Grid>
                              )

                            })


                          }

                        </Grid>
                      </Grid>
                    </>
                  );
                })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              className="dcBtDelete"
              onClick={() =>
                dialogHandleDelete(
                  viewrole && viewrole.role_id,
                  viewrole && viewrole.application_id
                )
              }
            >
              Delete
            </Button>
            <Button
              variant="contained"
              className="dcBtEdit"
              onClick={() => handleEdit(viewrole)}
            >
              Edit
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          classes={{ paper: classes.dialogRoot }}
          onClose={dialogHandleCloseDelete}
          aria-labelledby="customized-dialog-title"
          open={exportpopup}
        >
          <Grid container direction="row" alignItems="center">
            <DialogTitle
              id="customized-dialog-title"
              onClose={dialogHandleCloseDelete}
            >
              Select Columns to Export
            </DialogTitle>
          </Grid>
          <DialogContent>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0089ff" }}
                      color="primary"
                      checked={exportRadioBtn.role_name}
                      onChange={exportChange}
                      name="role_name"
                    />
                  }
                  label="Role Name"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0089ff" }}
                      color="primary"
                      checked={exportRadioBtn.description}
                      onChange={exportChange}
                      name="description"
                    />
                  }
                  label="Description"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0089ff" }}
                      color="primary"
                      checked={exportRadioBtn.no_of_users}
                      onChange={exportChange}
                      name="no_of_users"
                    />
                  }
                  label="Number of Users"
                />
              </FormGroup>
            </FormControl>
          </DialogContent>
          <Divider variant="middle" classes={{ middle: classes.dividerColor }} />

          <DialogActions>
            <Button
              onClick={dialogHandleCloseDelete}
              variant="outlined"
              className="dcBtDelete"
            >
              Cancel
            </Button>
            <Button
              onClick={handleExport}
              variant="contained"
              className="dcBtEdit"
            >
              Export
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {error && (
        <ErrorDialog open={error} message={errMsg} updateError={updateError} />
      )}
    </React.Fragment>
  );
}

export default function Roles() {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      <CustomizedTables />
    </SnackbarProvider>
  );
}
